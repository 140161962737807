import axios from "axios";

const qs = require("qs");

export default function (endpoint) {
	const state = {
		all: [],
		res: [],
	};

	const getters = {
		byId: state => (field, id) => {
			var data = [];
			var idx = 0;

			state.all.forEach(item => {
				if (eval("item." + field) == id) {
					data[idx] = item;
					idx++;
				}
			});

			return data || {};
		},
	};

	const mutations = {
		updateAll(state, data) {
			state.res = data;
			state.all = data;
		},
		merge(state, data) {
			state.res = data;
			state.all.push(data);
		},
		updateOne(state, data) {
			state.res = data;

			state.all.forEach((item, key) => {
				if (item.Id == data.Id) {
					state.all.splice(key, 1, data);
				}
			});
		},
		deleteOne(state, data) {
			state.res = data;

			state.all.forEach((item, key) => {
				if (item.Id == data.Id) {
					state.all.splice(key, 1);
				}
			});
		},
		resetRes(state, data) {
			state.res = data;
		},
	};

	const actions = {
		combo(context, id) {
			let url = `${endpoint}/combo`;

			if (id != undefined) url += "?id=" + id;

			return axios.get(url).then(res => {
				context.commit("updateAll", res.data);
			});
		},
		getAll(context, id) {
			let url = endpoint;

			if (id != undefined) url += "?id=" + id;

			return axios.get(url).then(res => {
				context.commit("updateAll", res.data);
			});
		},
		create(context, data) {
			data = qs.stringify(data);
			return axios.post(endpoint, data).then(res => {
				context.commit("merge", res.data);
			});
		},
		update(context, data) {
			let url = endpoint;

			url += "/" + data.Id;
			delete data["Id"];

			data = qs.stringify(data);
			return axios.post(url, data).then(res => {
				context.commit("updateOne", res.data);
			});
		},
		delete(context, data) {
			let url = endpoint;

			url += `/${data}/delete`;

			return axios.post(url).then(res => {
				context.commit("deleteOne", res.data);
			});
		},
		resetRes(context) {
			context.commit("resetRes", []);
		},
	};

	return {
		state,
		actions,
		mutations,
		getters,
		namespaced: true,
	};
}
