<template>
  <v-app>
    <my-header />
    <side-menu />
    <messages />

    <router-view />

    <v-footer app>
      <v-flex xs12 text-center>
        &copy;{{ year }} -&nbsp; desenvolvido por
        <a href="http://www.netsystem.com.br" target="_blank">NetSystem</a>
        <span v-if="!func.isMobile()">
          &nbsp;&nbsp;-&nbsp;&nbsp;
          <v-icon class="mr-2">mdi-whatsapp</v-icon>(31) 98510-1310
        </span>
      </v-flex>
    </v-footer>
  </v-app>
</template>

<script>
import Header from "../components/Header";
import Messages from "../components/Messages.vue";
import SideMenu from "../components/SideMenu.vue";
import func from "../utils/funcoes";
import jwt from "jsonwebtoken";

export default {
  data () {
    return {
      func,
    };
  },
  mounted () {
    if (func.empty(this.usuario.Id)) {
      let token = window.localStorage.getItem("token");
      let data = jwt.verify(token, process.env.VUE_APP_JWT_SECRET);
      this.$store.dispatch("auth/setUserData", data.usuario);
    }
  },
  computed: {
    usuario () {
      return this.$store.state.auth.userData;
    },
    year () {
      return new Date().getFullYear();
    },
  },
  components: {
    "my-header": Header,
    "side-menu": SideMenu,
    Messages,
  },
  watch: {
    $route () {
      this.testaToken();
    },
  },
  methods: {
    testaToken () {
      window.axios.get("/api/auth/ping")
        .then(res => {
          if (func.empty(res.data.status)) this.logout();
        })
        .catch(() => {
          this.$store.dispatch("auth/logout");
        });
    },
    logout () {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
  },
};
</script>

<style lang="css">
@import url("../assets/css/netsystem.css");
</style>
