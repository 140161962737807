const qs = require("qs");

const state = {
	isLogged: false,
	userData: {},
};

const mutations = {
	updateLogged(state, data) {
		state.isLogged = data;
	},
	updateUserData(state, data) {
		state.userData = data;
	},
	updateHeaders(state, data) {
		window.axios.defaults.headers.common["Authorization"] = data.token;
		window.axios.defaults.headers.common["Content-Type"] = "application/json";
		window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

		window.localStorage.setItem("token", data.token);
	},
};

const actions = {
	login(context, data) {
		if (data.token != undefined) {
			context.commit("updateHeaders", data);
			context.commit("updateLogged", true);
			return;
		}

		data = qs.stringify(data);

		return window.axios.post("/api/auth/token", data).then(res => {
			if (res.data.token != undefined) {
				context.commit("updateHeaders", res.data);
				context.commit("updateLogged", true);
			}
			return res.data;
		});
	},
	logout(context) {
		window.localStorage.removeItem("token");
		context.commit("updateUserData", "{}");
		context.commit("updateLogged", false);
	},
	setLogged(context, data) {
		context.commit("updateLogged", data);
	},
	setUserData(context, data) {
		context.commit("updateUserData", data);
	},
};

const namespaced = true;

export default {
	state,
	mutations,
	actions,
	namespaced,
};
