import {eventHub} from "./eventHub";

var elem = document.documentElement;
const milisecondsPerMinute = 1000 * 60;
const milisecondsPerHour = milisecondsPerMinute * 60;
const milisecondsPerDay = milisecondsPerHour * 24;
const tblStatus = [
	{Id: null, Nome: "Qualquer"},
	{Id: 0, Nome: "Em produção"},
	{Id: 1, Nome: "Finalizado"},
];
const statusNota = [
	{Id: "NA", Nome: "NA"},
	{Id: "POSSUI", Nome: "Possui"},
	{Id: "NAO_SE_APLICA", Nome: "Não se aplica"},
];
const tiposUsuario = [
	{Id: null, Nome: "Qualquer"},
	{Id: "A", Nome: "Administrador"},
	{Id: "U", Nome: "Usuário"},
];

String.prototype.toTitleCase = function () {
	if (this.indexOf(" ") > -1) {
		return this.split(" ")
			.map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
			.join(" ");
	} else {
		return this.charAt(0).toUpperCase() + this.substring(1).toLowerCase();
	}
};

const isUUID = uuid => {
	let patterns = [
		/^[0-9a-f]{8}-[0-9a-f]{4}-1[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i,
		/^[0-9a-f]{8}-[0-9a-f]{4}-2[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i,
		/^[0-9a-f]{8}-[0-9a-f]{4}-3[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i,
		/^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
		/^[0-9a-f]{8}-[0-9a-f]{4}-5[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
	];
	let isValid = false;

	for (let idx in patterns) {
		let pattern = patterns[idx];
		if (uuid.match(pattern)) {
			isValid = true;
			break;
		}
	}

	return isValid;
};

const humanFileSize = size => {
	var units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	var i = 0;
	while (size >= 1024) {
		size /= 1024;
		++i;
	}
	return size.toFixed(1) + " " + units[i];
};

const buscaCEP = async (cep, callback) => {
	fetch("https://api.postmon.com.br/v1/cep/" + cep)
		.then(response => {
			return response.json();
		})
		.then(res => {
			callback(res);
		})
		.catch(() => {
			callback(null);
			//console.log("ERROR:", error);
		});
};

const addNegrito = (texto, term) => {
	let txt = removeAcento(texto);
	let trm = removeAcento(term);
	let init = txt.toLowerCase().indexOf(trm.toLowerCase());
	let ret;
	if (init < 0) return texto;

	init = init < 0 ? 0 : init;

	ret =
		texto.substring(0, init) +
		"<b>" +
		texto.substring(init, term.length) +
		"</b>" +
		texto.substring(init + term.length);
	return ret.replace(new RegExp("^(.+)([(].+[)])(.+)?$", "gi"), "$1<small>$2</small>$3");
};

const removeAcento = (vlr, traco = true) => {
	if (empty(vlr)) return "";
	let text = vlr;

	text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
	text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
	text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
	text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
	text = text.replace(new RegExp("[ÚÙÛÜ]", "gi"), "u");
	text = text.replace(new RegExp("[Ñ]", "gi"), "n");
	text = text.replace(new RegExp("[Ç]", "gi"), "c");
	if (traco) text = text.replace(new RegExp("[ _]", "gi"), "-");
	text = text.replace(new RegExp("[*$%&()<>|#@.,;:/!?]", "gi"), "");

	return text;
};

const tituloBuscadores = str => {
	return removeAcento(str).toLocaleLowerCase();
};

const inArray = (dados, vlr) => {
	if (typeof dados === "string") dados = dados.split(",");

	for (var x = 0; x < dados.length; x++) {
		if (dados[x] == vlr) return true;
	}

	return false;
};

const getToArray = () => {
	let items = location.href.split("?")[1];
	if (undefined === items) return;
	items = items.split("&");

	let get = {};
	items.forEach(item => {
		let dados = item.split("=");
		get[dados[0]] = dados[1];
	});

	return get;
};

const addDias = (data, qtd, hora = false) => {
	return alteraData(data, qtd, hora, "add");
};

const remDias = (data, qtd, hora = false) => {
	return alteraData(data, qtd, hora, "rem");
};

const rand = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

const alteraData = (data, qtd, hora, op) => {
	var atual, ret;

	if (data.indexOf("/") > -1) atual = new Date(formataData(data, "y"));
	else if (data.indexOf("-") > -1) atual = new Date(data);
	else return data;

	if (op == "add") ret = new Date(atual.getTime() + qtd * milisecondsPerDay);
	else if (op == "rem") ret = new Date(atual.getTime() - qtd * milisecondsPerDay);
	else return data;

	let dia = ret.getUTCDate();
	let mes = ret.getUTCMonth() + 1;
	let ano = ret.getUTCFullYear();
	let hr = ret.getHours();
	let min = ret.getMinutes();
	let seg = ret.getSeconds();

	if (!hora) return [(dia < 10 ? "0" : "") + dia, (mes < 10 ? "0" : "") + mes, ano].join("/");
	else
		return [(dia < 10 ? "0" : "") + dia, (mes < 10 ? "0" : "") + mes, ano].join("/") + " " + [hr, min, seg].join(":");
};

const vldCpfCnpj = doc => {
	if (!empty(doc)) {
		doc = doc.replace(/\D/g, "");

		if (doc.length == 11) return vldCpf(doc);
		else if (doc.length == 14) return vldCnpj(doc);
		else return false;
	} else return false;
};

const vldCpf = cpf => {
	cpf = cpf.replace(/\D/g, "");

	if (
		empty(cpf) ||
		cpf.length != 11 ||
		cpf == "00000000000" ||
		cpf == "11111111111" ||
		cpf == "22222222222" ||
		cpf == "33333333333" ||
		cpf == "44444444444" ||
		cpf == "55555555555" ||
		cpf == "66666666666" ||
		cpf == "77777777777" ||
		cpf == "88888888888" ||
		cpf == "99999999999"
	)
		return false;
	var soma = 0;
	var resto;
	for (var i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
	resto = (soma * 10) % 11;
	if (resto == 10 || resto == 11) resto = 0;
	if (resto != parseInt(cpf.substring(9, 10))) return false;
	soma = 0;
	for (i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
	resto = (soma * 10) % 11;
	if (resto == 10 || resto == 11) resto = 0;
	if (resto != parseInt(cpf.substring(10, 11))) return false;
	return true;
};

const vldCnpj = cnpj => {
	cnpj = cnpj.replace(/\D/g, "");

	if (
		empty(cnpj) ||
		cnpj.length != 14 ||
		cnpj == "00000000000000" ||
		cnpj == "11111111111111" ||
		cnpj == "22222222222222" ||
		cnpj == "33333333333333" ||
		cnpj == "44444444444444" ||
		cnpj == "55555555555555" ||
		cnpj == "66666666666666" ||
		cnpj == "77777777777777" ||
		cnpj == "88888888888888" ||
		cnpj == "99999999999999"
	)
		return false;
	var tamanho = cnpj.length - 2;
	var numeros = cnpj.substring(0, tamanho);
	var digitos = cnpj.substring(tamanho);
	var soma = 0;
	var pos = tamanho - 7;
	for (var i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2) pos = 9;
	}
	var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	if (resultado != digitos.charAt(0)) return false;
	tamanho = tamanho + 1;
	numeros = cnpj.substring(0, tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2) pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	if (resultado != digitos.charAt(1)) return false;
	return true;
};

const diffDataDays = (a, b = false) => {
	a = new Date(formataData(a, "y"));
	if (b == false) b = new Date(formataData(agora("br", false), "y"));
	else b = new Date(formataData(b, "y"));

	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

	return Math.floor((utc1 - utc2) / milisecondsPerDay);
};

const timestampToDate = (timestamp, tipo = "br", h = false) => {
	let data = new Date(timestamp * 1000);
	let dia = data.getDate().toString().padStart(2, "0");
	let mes = (data.getMonth() + 1).toString().padStart(2, "0");
	let ano = data.getFullYear();

	let hr = data.getHours().toString().padStart(2, "0");
	let min = data.getMinutes().toString().padStart(2, "0");
	let seg = data.getSeconds().toString().padStart(2, "0");

	let hora = `${hr}:${min}:${seg}`;

	switch (tipo) {
		case "br":
			return dia + "/" + mes + "/" + ano + (h ? ` ${hora}` : "");
		case "en":
			return mes + "/" + dia + "/" + ano + (h ? ` ${hora}` : "");
		case "my":
			return ano + "-" + mes + "-" + dia + (h ? ` ${hora}` : "");
	}
};

const notifica = (msg, local, cor, tempo = 3000) => {
	var message = {
		Open: true,
		Texto: msg,
		Cor: cor == "atencao" ? "#757575" : cor,
		x: "center",
		y: local,
		timeout: tempo,
	};
	eventHub.$emit("snackbar-msg", {Open: false});
	setTimeout(() => {
		eventHub.$emit("snackbar-msg", message);
	}, 200);
};

const mensagem = (titulo, msg, cor = "") => {
	var mensagem = {
		Open: true,
		Titulo: titulo,
		Texto: msg,
		Cor: cor,
	};
	eventHub.$emit("dialog-msg", mensagem);
};

const mascara = (v, str, tam, dir = "e") => {
	const ret = v.toString().trim();
	const qtd = tam - ret.length;
	const mask = str.repeat(qtd);

	return dir == "e" ? mask + ret : ret + mask;
};

const empty = value => {
	return (
		undefined === value ||
		null === value ||
		false === value ||
		(typeof value === "object" && value.length == 0) ||
		"" === value
	);
};

const isInteger = x => {
	return typeof x === "number" && isFinite(x) && Math.floor(x) === x;
};

const isFloat = x => {
	return !!(x % 1);
};

const isNumber = value => {
	if (undefined === value || null === value || "" === value) return false;
	if (typeof value == "number") return true;

	return !isNaN(value - 0);
};

const isStrNumber = value => {
	if (undefined === value || null === value || "" === value) return false;
	if (typeof value == "number") return true;

	value = value.toString().replace(/./g, "").replace(/,/g, ".");

	return !isNaN(value - 0);
};

const roundTo = (num, places) => {
	return +(Math.round(num + "e+" + places) + "e-" + places);
};

const numFormat = (vlr, pos, sepDec, sepMil = "") => {
	let arrAux = [];
	let ret = 0;
	let v = vlr;

	// converte para o padrão americano e limpa a máscara
	if (isNumber(v)) {
		v = v.toString().replace(/,/g, "");
		v = roundTo(v, pos);
	} else if (isStrNumber(v)) {
		v = v.toString().replace(/\./g, "").replace(/,/g, ".");
		v = roundTo(v, pos);
	} else {
		if (sepDec == ".") return "0.00";
		else return "0,00";
	}

	// separ a parte inteira da decimal
	if (v.toString().indexOf(".") > -1) {
		arrAux = v.toString().split(".");
		if (arrAux[0] == "") arrAux[0] = "0";
		arrAux[1] = arrAux[1].toString().substring(0, pos);
		arrAux[1] = mascara(arrAux[1], "0", pos, "d");
	} else {
		arrAux[0] = v.toString();
		arrAux[1] = mascara("0", "0", pos);
	}

	// insere o separador de milhar
	if (sepMil != "" && arrAux[0].length > 3) {
		var aux = invertString(arrAux[0]).split("");
		var aux2 = "";
		var cont = 0;

		for (var x = 0; x < aux.length; x++) {
			aux2 = aux[x] + aux2;
			cont++;

			if (cont == 3 && x < aux.length - 1) {
				aux2 = sepMil + aux2;
				cont = 0;
			}
		}
		arrAux[0] = aux2;
	}

	// insere o separador de decimal
	if (sepDec == "") ret = arrAux[0];
	else ret = arrAux[0] + sepDec + arrAux[1];

	return ret;
};

const invertString = v => {
	return v.split("").reverse().join("");
};

const jsonToFormData = data => {
	let fd = new FormData();
	let keys = Object.keys(data);
	let x = 0;

	for (x = 0; x < keys.length; x++) {
		if (typeof keys[x] === "object") fd.append(keys[x], JSON.stringify(data[keys[x]]));
		else fd.append(keys[x], data[keys[x]]);
	}

	return fd;
};

const jsonToQueryString = (data, keylower = false) => {
	if (empty(data)) return "";

	const queryString = [];

	for (const [key, val] of Object.entries(data)) {
		if (empty(val)) continue;

		let encodedKey = encodeURIComponent(key);
		if (keylower) encodedKey = encodedKey.toLocaleLowerCase();

		if (typeof val === "object") {
			const subQuery = jsonToQueryString(val);
			if (!empty(subQuery)) queryString.push(subQuery);
		} else {
			queryString.push(`${encodedKey}=${encodeURIComponent(val)}`);
		}
	}

	return queryString.join("&");
};

const dataURLToBlob = dataURL => {
	let BASE64_MARKER = ";base64,";
	let parts = null;
	let contentType = null;
	let raw = null;

	if (dataURL.indexOf(BASE64_MARKER) == -1) {
		parts = dataURL.split(",");
		contentType = parts[0].split(":")[1];
		raw = parts[1];
		return new Blob([raw], {type: contentType});
	}

	parts = dataURL.split(BASE64_MARKER);
	contentType = parts[0].split(":")[1];
	raw = window.atob(parts[1]);
	let rawLength = raw.length;
	let uInt8Array = new Uint8Array(rawLength);

	for (let i = 0; i < rawLength; ++i) uInt8Array[i] = raw.charCodeAt(i);

	return new Blob([uInt8Array], {type: contentType});
};

const fileToBase64 = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			let block = reader.result.split(";");
			let realData = block[1].split(",")[1];
			resolve(realData);
		};
		reader.onerror = error => reject(error);
	});

const b64toBlob = (b64Data, contentType, sliceSize) => {
	contentType = contentType || "";
	sliceSize = sliceSize || 512;

	var byteCharacters = window.atob(b64Data);
	var byteArrays = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	var blob = new Blob(byteArrays, {type: contentType});
	return blob;
};

const abrirLink = (pLink, pNew = false) => {
	const newTab = pNew ? window.open() : null;
	if (newTab) {
		newTab.opener = null;
		newTab.location = pLink;
	} else {
		location.href = pLink;
	}
};

const agora = (tipo = "br", h = true) => {
	const hoje = new Date();
	const dia = hoje.getDate().toString().padStart(2, "0");
	const mes = (hoje.getMonth() + 1).toString().padStart(2, "0");
	const ano = hoje.getFullYear().toString();
	const hora = `${hoje.getHours().toString().padStart(2, "0")}:${hoje.getMinutes().toString().padStart(2, "0")}:${hoje
		.getSeconds()
		.toString()
		.padStart(2, "0")}`;

	let data = "";

	switch (tipo) {
		case "br":
			data = `${dia}/${mes}/${ano}`;
			break;
		case "en":
			data = `${mes}/${dia}/${ano}`;
			break;
		case "my":
			data = `${ano}-${mes}-${dia}`;
			break;
		default:
			throw new Error("Tipo inválido");
	}

	return h ? `${data} ${hora}` : data;
};

const formataData = (str, tipo) => {
	let mask1 = /^[\d]{2}\/[\d]{2}\/[\d]{4}(\s[\d]{2}:[\d]{2}:[\d]{2})?$/;
	let mask2 = /^[\d]{4}-[\d]{2}-[\d]{2}(\s[\d]{2}:[\d]{2}:[\d]{2})?$/;
	if (!mask1.test(str) && !mask2.test(str)) return null;

	const [data, hora] = str.split(" ");

	if (tipo == "y") {
		const [day, month, year] = data.split("/");
		return year.toString() + "-" + month.toString() + "-" + day.toString() + (hora ? " " + hora : "");
	} else if (tipo == "d") {
		const [year, month, day] = data.split("-");
		return day.toString() + "/" + month.toString() + "/" + year.toString() + (hora ? " " + hora : "");
	}
};

const vldCombo = (combo, campo = "Id") => {
	return empty(combo) ? undefined : combo[campo];
};

const vldData = str => {
	if (empty(str)) return false;

	let numeros = str.replace(/\D/g, "");
	if (numeros.length != 8) return false;
	if (str.indexOf("/") > -1) str = formataData(str, "y");

	var regex_date = /^\d{4}-\d{1,2}-\d{1,2}$/;
	if (!regex_date.test(str)) return false;

	var parts = str.split("-");
	var day = parseInt(parts[2], 10);
	var month = parseInt(parts[1], 10);
	var year = parseInt(parts[0], 10);

	if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

	var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
	if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;

	return day > 0 && day <= monthLength[month - 1];
};

const vldHora = str => {
	if (empty(str)) return false;
	if (str.indexOf(":") == -1) return false;
	if (str.length < 3 || str.length > 5) return false;

	var regex_hora = /^\d{2}:\d{2}(:\d{2})?$/;
	if (!regex_hora.test(str)) return false;

	var parts = str.split(":");
	if (parts[0] > 23) return false;
	if (parts[1] > 59) return false;

	return true;
};

const vldNome = str => {
	var regex = /^[a-zA-Z'\u00C0-\u00FF]+(\s[a-zA-Z.'\u00C0-\u00FF]+)+\s?$/;
	return regex.test(str);
};

const vldRazaoSocial = str => {
	var regex = /^[\w&-'\u00C0-\u00FF]+(\s[\w&-.'\u00C0-\u00FF]+)+\s?$/;
	return regex.test(str);
};

const vldFone = str => {
	var regex = /^\(\d{2}\)\s\d{4,5}-\d{4}$/;
	return regex.test(str);
};

const vldMail = str => {
	var regex = /^[0-9a-z]([-_.]?[0-9a-z])*@[0-9a-z]([-.]?[0-9a-z])*.[a-z]{2,8}$/;
	return regex.test(str);
};

const vldUrl = str => {
	var regex = /^((https?|ftp):\/\/)?(localhost|(.?\w[\w-]*)+.([a-zA-Z]{2,8}|\d{1,3}))(:\d{2,5})?([/|?].*)?$/;
	return regex.test(str);
};

const isMobile = () => {
	var pattern = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i;
	if (pattern.test(navigator.userAgent)) return true;
	else return false;
};

const openFullscreen = () => {
	const requestMethod =
		elem.requestFullscreen || elem.mozRequestFullScreen || elem.webkitRequestFullscreen || elem.msRequestFullscreen;
	if (requestMethod) {
		requestMethod.call(elem);
	}
};

const closeFullscreen = () => {
	const exitMethods = ["exitFullscreen", "mozCancelFullScreen", "webkitExitFullscreen", "msExitFullscreen"];

	for (let i = 0; i < exitMethods.length; i++) {
		if (document[exitMethods[i]]) {
			document[exitMethods[i]]();
			return;
		}
	}
};

export default {
	milisecondsPerMinute,
	milisecondsPerHour,
	milisecondsPerDay,
	tblStatus,
	statusNota,
	tiposUsuario,
	isUUID,
	humanFileSize,
	buscaCEP,
	removeAcento,
	addNegrito,
	tituloBuscadores,
	inArray,
	getToArray,
	remDias,
	addDias,
	rand,
	vldCpfCnpj,
	vldCnpj,
	vldCpf,
	diffDataDays,
	timestampToDate,
	notifica,
	mensagem,
	mascara,
	empty,
	isInteger,
	isFloat,
	isNumber,
	isStrNumber,
	roundTo,
	numFormat,
	invertString,
	jsonToFormData,
	jsonToQueryString,
	fileToBase64,
	b64toBlob,
	dataURLToBlob,
	abrirLink,
	agora,
	formataData,
	vldCombo,
	vldData,
	vldHora,
	vldNome,
	vldRazaoSocial,
	vldFone,
	vldMail,
	vldUrl,
	isMobile,
	openFullscreen,
	closeFullscreen,
};
