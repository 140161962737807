<template>
  <div>
    <v-app-bar v-if="isLogged" dense dark fixed clipped-left color="primary">
      <v-app-bar-nav-icon @click="toggleSideMenu()"></v-app-bar-nav-icon>

      <v-app-bar-title v-if="usuario.Tipo == 'U'">
        <b>EMPRESA:</b> {{ usuario.NmEmpresa }}
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="white" v-bind="attrs" v-on="on" @click="logoff()">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>SAIR</span>
      </v-tooltip>
    </v-app-bar>
  </div>
</template>

<script>
import func from "../utils/funcoes";
import { eventHub } from "../utils/eventHub";

export default {
  data () {
    return {
      func,
      drawer: false,
    };
  },
  mounted () {
    eventHub.$off("updateDrawer");
    eventHub.$on("updateDrawer", (value) => {
      this.drawer = value;
    });

    if (!func.isMobile()) {
      setTimeout(() => {
        this.toggleSideMenu(true);
      }, 100);
    }
  },
  computed: {
    isLogged () {
      return this.$store.state.auth.isLogged;
    },
    usuario () {
      return this.$store.state.auth.userData;
    },
  },
  methods: {
    logoff () {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
    toggleSideMenu (value = false) {
      if (value) this.drawer = true;
      else this.drawer = !this.drawer;
      eventHub.$emit("setDrawer", this.drawer);
    },
  },
};
</script>