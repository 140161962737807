import Vue from "vue";
import Router from "vue-router";
import Padrao from "../components/Padrao";
import jwt from "jsonwebtoken";
import func from "../utils/funcoes";

Vue.use(Router);

const rules = {
	clientes: ["A"],
	servicos: ["A", "U"],
	usuarios: ["A"],
};

const validRoute = (to, from, next) => {
	let rota = to.fullPath.split("/")[1];
	let userData = jwt.verify(localStorage.getItem("token") ?? "", process.env.VUE_APP_JWT_SECRET);

	if (func.inArray(rules[rota], userData.usuario.Tipo)) next();
	else {
		func.notifica("ACESO NEGADO!!!", "top", "red");
		if (!func.empty(from.fullPath)) next(from.fullPath);
		else next("/");
	}
};

const routes = [
	{
		path: "/",
		redirect: "/login",
	},
	{
		path: "/login",
		component: () => import("../pages/Dashboard.vue"),
	},
	{
		path: "/dashboard",
		component: () => import("../pages/Dashboard.vue"),
	},
	{
		path: "/clientes",
		component: Padrao,
		children: [
			{
				path: "",
				component: () => import("../pages/clientes/Pesquisar.vue"),
			},
		],
		beforeEnter(to, from, next) {
			validRoute(to, from, next);
		},
	},
	{
		path: "/servicos",
		component: Padrao,
		children: [
			{
				path: "",
				component: () => import("../pages/servicos/Pesquisar.vue"),
			},
		],
		beforeEnter(to, from, next) {
			validRoute(to, from, next);
		},
	},
	{
		path: "/usuarios",
		component: Padrao,
		children: [
			{
				path: "",
				component: () => import("../pages/usuarios/Pesquisar.vue"),
			},
		],
		beforeEnter(to, from, next) {
			validRoute(to, from, next);
		},
	},
];

export default new Router({routes});
