<template>
  <v-app style="background: url(assets/img/fundo_login.jpg) center center">
    <messages />

    <v-main>
      <v-container>
        <v-row>
          <v-col cols="12" class="text-center mt-15">
            <img src="assets/img/logo.png" class="mb-20" />
            <br />
            <span class="white--text font-weight-bold" style="font-size: 25px;">
              - SISTEMA DE SERVIÇOS -
            </span>
          </v-col>

          <v-col cols="10" md="4" class="offset-1 offset-md-4">
            <v-card style="border-radius: 20px">
              <v-card-text class="text-center">
                <span class="text-h5 black--text">- LOGIN -</span>

                <v-form v-model="valid" ref="form" class="mt-5">
                  <v-text-field
                    autofocus outlined
                    ref="login"
                    label="Usuário"
                    v-model="data.login"
                    :rules="validation.login"
                    color="primary"
                    required
                    @focus="$event.target.select()"
                    @keyup.enter.prevent="$refs.senha.focus()" />

                  <v-text-field
                    outlined
                    ref="senha"
                    label="Senha"
                    v-model="data.senha"
                    :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                    :rules="validation.senha"
                    :type="show1 ? 'text' : 'password'" c
                    olor="primary"
                    required
                    @click:append="show1 = !show1"
                    @keyup.enter.prevent="valid ? submit() : $refs.login.focus()" />

                  <v-btn class="primary white--text" :disabled="!valid" @click="submit()">
                    LOGIN
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Messages from "../components/Messages.vue";
import func from "../utils/funcoes";
import { eventHub } from "../utils/eventHub";

export default {
  data () {
    return {
      func,
      valid: false,
      show1: false,
      data: {},

      validation: {
        login: [(v) => !!v || "USUÁRIO é obrigatório."],
        senha: [(v) => !!v || "SENHA é obrigatório."],
      },
    };
  },
  components: {
    Messages,
  },
  methods: {
    submit () {
      eventHub.$emit("dialog-aguarde", true);

      this.data.area = "E";

      this.$store
        .dispatch("auth/login", this.data)
        .then(() => {
          if (this.$store.state.auth.isLogged) {
            func.notifica(
              "Login efetuado com sucesso!!!",
              "top", "green", 3000
            );
            this.$router.push("/dashboard");
          }
        })
        .catch((error) => {
          if (error.response.status == 403 || error.response.status == 401)
            func.notifica(
              "Usuário ou senha inválida!!!",
              "top", "red", 5000
            );
          else func.notifica(error, "top", "red", 5000);
          this.$refs.login.focus();
        })
        .finally(() => {
          eventHub.$emit("dialog-aguarde", false);
        });
    },
  },
};
</script>

<style lang="css">
@import url("../assets/css/netsystem.css");
</style>
