<template>
  <v-navigation-drawer v-model="drawer" app clipped style="background-color: #f1f1f1;"
    @input="updDrawerHeader()">
    <!-- TÍTULO DO MENU -->

    <div class="img-circle d-flex align-center justify-center centralizado mb-2"
      style="margin-top: 70px;  width: 70px; height: 70px;">
      <img src="assets/img/favicon.png" style="width: 100%;" />
    </div>
    <div class="text-h5 white--text text-shadow text-center font-weight-bold mb-5">
      SERVIÇOS
    </div>

    <!-- MENU -->
    <v-list nav dense>
      <!-- DEMAIS CADASTROS -->
      <v-list-item v-for="item, i in mnuFiltrado" :key="i" :to="item.link">
        <v-list-item-icon>
          <v-icon v-text="item.icone"></v-icon>
        </v-list-item-icon>
        <v-list-item-title v-text="item.titulo"></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import func from "../utils/funcoes";
import { eventHub } from "../utils/eventHub";

export default {
  data () {
    return {
      func,
      drawer: false,
      mnuCadastros: [
        {
          titulo: "Dashboard",
          link: "/dashboard",
          icone: "mdi-chart-areaspline",
          users: ["A", "U"]
        },
        {
          titulo: "Clientes",
          link: "/clientes",
          icone: "mdi-account-group-outline",
          users: ["A"]
        },
        {
          titulo: "Serviços",
          link: "/servicos",
          icone: "mdi-account-hard-hat",
          users: ["A", "U"]
        },
        {
          titulo: "Usuários",
          link: "/usuarios",
          icone: "mdi-account",
          users: ["A"]
        }
      ],
    };
  },
  mounted () {
    eventHub.$off("setDrawer");
    eventHub.$on("setDrawer", (value) => {
      this.drawer = value;
    });
  },
  computed: {
    usuario () {
      return this.$store.state.auth.userData;
    },
    mnuFiltrado () {
      return this.mnuCadastros.filter((m) => func.inArray(m.users, this.usuario.Tipo));
    }
  },
  methods: {
    updDrawerHeader () {
      eventHub.$emit("updateDrawer", this.drawer);
    },
  },
};
</script>

<style scoped>
.v-list-item {
  border: solid 1px transparent;
}

.v-list-item,
.v-list-item .v-list-item__icon .v-icon,
.v-list-item .v-list-item__title {
  color: var(--primary);
  transition: 300ms ease-in-out;
}

.v-list-item:hover {
  background: var(--primary-lighten) !important;
  border: solid 1px var(--primary) !important;
}

.v-list-item:hover .v-list-item__icon .v-icon,
.v-list-item:hover .v-list-item__title {
  color: var(--primary) !important;
}

.v-list-item--active {
  background: var(--primary-lighten);
  border: solid 1px var(--primary);
}

.v-list-item--active .v-list-item__icon,
.v-list-item--active .v-list-item__title {
  color: var(--primary);
}

.v-list-group.v-list-group--active {
  border: solid 1px transparent;
  border-radius: 5px;
  background: #fff;
}

.v-list-group__header.v-list-item--active {
  background-color: var(--primary-light);
  border: solid 1px transparent;
}

.v-list-group__header.v-list-item--active .v-list-item__icon,
.v-list-group__header.v-list-item--active .v-list-item__title {
  color: #fff;
}
</style>