<template>
  <div>
    <templateDefault v-if="isLogged" />
    <templateLogin v-else-if="!isLogged" />
  </div>
</template>

<script>
import func from "./utils/funcoes";
import templateDefault from "./templates/default";
import templateLogin from "./templates/login";

export default {
  components: {
    templateDefault,
    templateLogin,
  },
  data () {
    return {
      func,
    };
  },
  computed: {
    isLogged () {
      return this.$store.state.auth.isLogged;
    },
  }
};
</script>