"use_stict";

import axios from "axios";
import store from "../store";
import Vue from "vue";

const token = window.localStorage.getItem("token");
const _axios = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	timeout: 30 * 1000,
});

if (token) {
	_axios.defaults.headers.common["Authorization"] = token;
	_axios.defaults.headers.common["Content-Type"] = "application/json";
	store.commit("auth/updateLogged", true);
}

window.axios = _axios;

Vue.use({
	install(Vue) {
		Vue.prototype.$axios = _axios;
	},
});
