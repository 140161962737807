<template>
  <div>
    <!-- CAIXA DE MENSAGEM -->
    <v-dialog v-model="dialog1" scrollable max-width="1024">
      <v-card>
        <v-toolbar
          :color="(mensagem.Cor == 'ERROR'
            ? ' red darken-2'
            : mensagem.Cor == 'SUCCESS'
              ? ' success'
              : ' grey darken-3')"
          class="text-h6" dark>
          {{ mensagem.Titulo }}
          <v-spacer></v-spacer>
          <v-btn icon @click.stop.prevent="dialog1 = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-4">
          <span style="font-size: 1.15em" v-html="mensagem.Texto"></span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="blue darken-2" @click.stop.prevent="dialog1 = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CAIXA DE DIÁLOGO EXCLUIR REGISTRO -->
    <v-dialog v-model="dlgremover" scrollable max-width="800">
      <v-card>
        <v-toolbar color="red" class="text-h6" dark>
          {{ mensagem.Titulo }}
          <v-spacer></v-spacer>
          <v-btn icon @click.stop.prevent="fechaDlgExcluir()">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />
          <span style="font-size: 1.15em" v-html="mensagem.Texto"></span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            v-if="regExcluir != false"
            color="red"
            @click.stop.prevent="excluir(regExcluir, true)">
            SIM
          </v-btn>
          <v-btn text color="blue darken-2" @click.stop.prevent="fechaDlgExcluir()">CANCELAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CAIXA DE DIÁLOGO CONFIRMAR AÇÃO -->
    <v-dialog v-model="dlgconfirmar" max-width="800">
      <v-card>
        <v-toolbar color="red darken-1" class="text-h6" dark>
          {{ mensagem.Titulo }}
          <v-spacer></v-spacer>
          <v-btn icon @click.stop.prevent="confirma(false)">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />
          <span style="font-size: 1.15em" v-html="mensagem.Texto"></span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="blue darken-2" @click.stop.prevent="confirma(true)">SIM</v-btn>
          <v-btn text color="red" @click.stop.prevent="confirma(false)">NÃO</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- LAYER AGUARDE... -->
    <v-overlay :value="dlgAguarde">
      <v-progress-circular indeterminate size="65" color="white"></v-progress-circular>&nbsp;
      AGUARDE...
    </v-overlay>

    <!-- CAIXA DE NOTIFICAÇÃO -->
    <v-snackbar
      v-model="snack"
      :top="snackbar.y === 'top'"
      :bottom="snackbar.y === 'bottom'"
      :left="snackbar.x === 'left'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :color="snackbar.Cor"
      :multi-line="true">
      <b class="font-size-15" v-html="snackbar.Texto"></b>
      <v-btn
        icon
        color="white"
        class="float-right ml-3 black--text"
        @click.stop.prevent="snack = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import func from "../utils/funcoes";
import { eventHub } from "../utils/eventHub";

export default {
  data () {
    return {
      func,
      dialog1: false,
      dlgremover: false,
      dlgconfirmar: false,
      dlgAguarde: false,
      regExcluir: false,
      acaoRetorno: null,
      snack: false,
      mensagem: {},
      snackbar: {},

      cliques: 0,
    };
  },
  created () {
    eventHub.$off("dialog-msg");
    eventHub.$on("dialog-msg", (data) => {
      if (data.Open == true) {
        this.mensagem.Titulo = data.Titulo;
        this.mensagem.Texto = data.Texto;
        this.mensagem.Cor = data.Cor;
      }
      this.dialog1 = data.Open;
    });

    eventHub.$off("dialog-excluir");
    eventHub.$on("dialog-excluir", (data) => {
      if (data.Open == true) {
        this.regExcluir = data.Reg;
        this.acaoRetorno = data.Retorno;
        this.mensagem.Titulo = data.Titulo;
        this.mensagem.Texto = data.Texto;
      }
      this.dlgremover = data.Open;
    });

    eventHub.$off("dialog-confirmar");
    eventHub.$on("dialog-confirmar", (data) => {
      if (data.Open == true) {
        this.acaoRetorno = data.Retorno;
        this.mensagem.Titulo = data.Titulo;
        this.mensagem.Texto = data.Texto;
      }
      this.dlgconfirmar = data.Open;
    });

    eventHub.$off("snackbar-msg");
    eventHub.$on("snackbar-msg", (data) => {
      if (data.Open == true) {
        this.snackbar.Texto = data.Texto;
        this.snackbar.Cor = data.Cor;
        this.snackbar.x = data.x;
        this.snackbar.y = data.y;
        this.snackbar.timeout = data.timeout;
      }
      this.snack = data.Open;
    });

    eventHub.$off("dialog-aguarde");
    eventHub.$on("dialog-aguarde", (data) => {
      this.dlgAguarde = data;
    });
  },
  methods: {
    excluir (reg, flag) {
      this.cliques++;
      if (this.cliques > 1) return;
      setTimeout(() => {
        this.cliques = 0;
      }, 1000);

      let data = {};
      data.reg = reg;
      data.flag = flag;

      this.regExcluir = false;
      this.dlgremover = false;

      eventHub.$emit(this.acaoRetorno, data);
      this.acaoRetorno = null;
    },
    confirma (flag) {
      this.cliques++;
      if (this.cliques > 1) return;
      setTimeout(() => {
        this.cliques = 0;
      }, 1000);

      this.dlgconfirmar = false;
      eventHub.$emit(this.acaoRetorno, flag);
      this.acaoRetorno = null;
    },
    fechaDlgExcluir () {
      this.cliques++;
      if (this.cliques > 1) return;
      setTimeout(() => {
        this.cliques = 0;
      }, 1000);

      this.dlgremover = false;
    },
  },
};
</script>