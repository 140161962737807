import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: {pt},
		current: "pt",
	},
	icons: {
		iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
	},
	theme: {
		themes: {
			light: {
				primary: "#1A237E",
				secondary: "#7986CB",
				accent: "#1A237E",
				error: "#F44336",
			},
		},
	},
});
